import React from "react"
import Layout from "hoc/Layout"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import { graphql, useStaticQuery } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { options } from "../constants/richTextOptions"

const RulesPage = () => {
  const breadcrumbsData = [
    {
      title: "Startseite",
      link: "/",
    },
    "Datenschutz-Bestimmungen",
  ]

  const data = useStaticQuery(graphql`
    query TermsAndRulesQuery {
      allContentfulStronaRegulaminuIZgod(limit: 1) {
        nodes {
          content {
            raw
          }
        }
      }
    }
  `)

  const text = data.allContentfulStronaRegulaminuIZgod.nodes[0].content
  return (
    <Layout
      breadcrumbsData={breadcrumbsData}
      seoTitle="Datenschutz-Bestimmungen"
    >
      <Hr mb={4} mt={6} />
      <Font42 mb={4}>Datenschutz-Bestimmungen</Font42>
      {renderRichText(text, options)}
    </Layout>
  )
}

export default RulesPage
